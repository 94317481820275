import React from 'react';
import iphone from '../../Assets/hero/iphone.webp';

const MobileApp = () => {
  return (
    <>
    <section className="mobile-app-modules">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="mobile-app-content">
                        <h3>Mobile Application</h3>
                        <p>Barekaab's mobile app offers students, parents, and educators easy access to educational resources, grades, assignments, and communication tools, enhancing learning and collaboration on the go.</p>

                        <button>Free Trial</button>
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="mobile-app-img">
                        <img src={iphone} alt="iphone" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default MobileApp