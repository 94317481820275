import React from "react";

const ThankYou = () => {
  return (
    <>
      <div className="thank-you-section">
        <div className="container">
          <div className="card-thank-you">
            <header className="site-header" id="header">
              <h1 className="site-header__title" data-lead-id="site-header-title">
                THANK YOU!
              </h1>
            </header>

            <div className="main-content">
              <i className="fa fa-thumbs-up main-content__checkmark" id="checkmark"></i>
              <hr />
              <p className="main-content__body" data-lead-id="main-content-body">
                <b>
                Thank you for choosing <a href="https://school.barekaab.com/" target="_blank" rel="noreferrer">Barekaab.com</a> to embark on your educational journey. We're thrilled to have you join our community dedicated to learning and growth. Here's to a fruitful and rewarding experience ahead! Welcome aboard!
                </b>
              </p>
            </div>

            <footer className="site-footer" id="footer">
              <p className="site-footer__fineprint" id="fineprint">
                Copyright 2024 | All Rights Reserved
              </p>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
