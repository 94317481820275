import React from 'react'
import Contact from '../../Components/Contact/Contact';

const ContactUs = () => {
  return (
    <>
    <section className="page-wrapper">
      <Contact />
    </section>
    </>
  )
}

export default ContactUs;