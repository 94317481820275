import React from 'react';

const ProductBenefit = () => {
  return (
    <>
    <section className="home-product-benefit-section">
        <div className="container">
            <div className="product-benefit-section">
                <h3>Our Product Benefit </h3>
                <p>Empowering Education, Every Step of the Way!</p>
            </div>

            <div className="row my-3">
                <div className="col-lg-6">
                    <div className="content-benefit">
                        <h3>Global Reach</h3>
                        <p>Our services extend worldwide, providing access to top-notch solutions and support to educational institutions regardless of their location.</p>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="content-benefit">
                        <h3>Tailored Solutions</h3>
                        <p>We understand that every institution is unique, which is why we provide customized solutions tailored to meet the specific needs and challenges of each client.</p>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="content-benefit">
                        <h3>Innovative Technologies</h3>
                        <p>We leverage cutting-edge technologies to deliver innovative solutions that enhance efficiency, productivity, and overall performance within educational institutions.</p>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="content-benefit">
                        <h3>Trusted Support</h3>
                        <p>As a reputable business consulting service, we offer reliable assistance and guidance to educational institutions, ensuring their success in implementing our solutions.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ProductBenefit;