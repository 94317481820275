import React from "react";
import about from "../../Assets/hero/about.webp";
import { useNavigate } from "react-router-dom";
const AboutUs = () => {
  const navigate = useNavigate();
  const goRegister = () => {
    navigate("/register");
  };
  return (
    <>
      <section className="home-about-section">
        <div className="container">
          <div className="home-about-content">
            <div className="row">
              <div className="col-lg-6">
                <div className="content-about">
                  <h3>About Us</h3>
                  <p>
                    At Barekaab, we understand the dynamic and intricate nature
                    of educational institutions. With a vision to streamline and
                    enhance the management processes within educational setups,
                    Barekaab Institution Management ERP Solution was born.{" "}
                  </p>
                  <p>
                    Our comprehensive ERP solution is meticulously crafted to
                    cater to the diverse needs of educational institutions,
                    ranging from schools and colleges to universities and
                    training centers.
                  </p>

                  <button onClick={goRegister}>Join Now</button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-img-about">
                  <img src={about} alt="about" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
