import React from 'react';
import client1 from "../../Assets/icon/client-1.webp";
import client2 from "../../Assets/icon/client-2.webp";
import client3 from "../../Assets/icon/client-3.webp";
import client4 from "../../Assets/icon/client-4.webp";
import client5 from "../../Assets/icon/client-5.webp";

const OurClient = () => {
  return (
    <>
    <div className="home-client-section">
      <div className="client-section">
        <h3>Our Clients</h3>
        <p>Your School, Our ERP Solution!</p>
      </div>

      <div className="client-marquee">
        <div className="marquee-client-container">
          <div className="content-marquee-client">
            <img src={client1} alt="client1" />
            <h3>Indian Institute of Management, Amritsar</h3>
          </div>
          <div className="content-marquee-client">
            <img src={client2} alt="client2" />
            <h3>Bits School Of Management </h3>
          </div>
          <div className="content-marquee-client">
            <img src={client3} alt="client3" />
            <h3>St. John University</h3>
          </div>
          <div className="content-marquee-client">
            <img src={client4} alt="client4" />
            <h3>Max International School</h3>
          </div>
          <div className="content-marquee-client">
            <img src={client5} alt="client5" />
            <h3>Garima Academy Education </h3>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default OurClient;