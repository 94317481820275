import React from "react";
import whyUs from "../../Assets/hero/whyUs.webp";
import { useNavigate } from "react-router-dom";
const WhyUs = () => {
  const navigate = useNavigate();
  const goContactUs = () => {
    navigate("/contact");
  };
  return (
    <>
      <section className="home-why-section">
        <div className="container">
          <div className="home-about-content">
            <div className="row">
              <div className="col-lg-6">
                <div className="content-about">
                  <h3>Why Us</h3>
                  <h6>Your at the right place.</h6>
                  <p>
                    Welcome to Barekaab School ERP! We're a top notch business
                    consulting service provider, dedicated to offering excellent
                    support and assistance to our clients worldwide. Our team
                    consists of enthusiastic individuals ready to tackle any
                    obstacles on our journey to success.
                  </p>

                  <button onClick={goContactUs}>Contact</button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-img-about">
                  <img src={whyUs} alt="whyUs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyUs;
