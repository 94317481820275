import React from "react";
import feature1 from "../../Assets/icon/feature-1.webp";
import feature2 from "../../Assets/icon/feature-2.webp";
import feature3 from "../../Assets/icon/feature-3.webp";
import feature4 from "../../Assets/icon/feature-4.webp";
import feature5 from "../../Assets/icon/feature 5.webp";
import feature6 from "../../Assets/icon/feature6.webp";
// import logo1 from "../../Assets/logo/image 2.png";
import icon1 from "../../Assets/icon/Users.png";
import icon2 from "../../Assets/icon/Vector.png";
import download from "../../Assets/icon/download.png";

const features = [
  {
    image: feature1,
    title: "Student Login",
    description:
      "Dive into Our School ERP Software Demo for an Interactive Learning Experience.",
    link: "https://school.barekaab.com/student-login",
  },
  {
    image: feature2,
    title: "Admin Login",
    description:
      "Navigate Through Our Admin Interface for Streamlined School Management.",
    link: "https://school.barekaab.com/admin-login",
  },
  {
    image: feature3,
    title: "Parent Login",
    description:
      "Access the Parent Login for a Window into Your Child's Educational Journey.",
    link: "https://school.barekaab.com/parent-login",
  },
  {
    image: feature4,
    title: "Teacher Login",
    description:
      "Discover the Power of Our Teacher Interface in Revolutionizing Classroom Management.",
    link: "https://school.barekaab.com/teacher-login",
  },
  {
    image: feature5,
    title: "Library Login",
    description:
      "Improve library access with our user-friendly login solution, catering to all users.",
    link: "https://school.barekaab.com/library-login",
  },
  {
    image: feature6,
    title: "HR Login",
    description:
      "Revolutionize HR management for all stakeholders with our powerful, seamless interface.",
    link: "https://school.barekaab.com/hr-login",
  },
];

// const appFeatures = [
//   {
//     title: "Student App",
//     description:
//       "Dive into Our Student App for Seamless Access to Education Anytime, Anywhere.",
//     link: "",
//   },
//   {
//     title: "Admin App",
//     description:
//       "Discover Our Admin Platform for Effortless and Seamless School Management.",
//     link: "",
//   },
//   {
//     title: "Parent App",
//     description:
//       "Dive into Our Parent App for Seamless Access to Education Anytime, Anywhere.",
//     link: "",
//   },
//   {
//     title: "Teacher App",
//     description:
//       "Discover the Power of Our Teacher Interface in Revolutionizing Classroom Management.",
//     link: "",
//   },
// ];

const OurFeatures = () => {
  const handleRedirect = (link) => {
    window.open(link, "_blank");
  };

  // const handleAppRedirect = (link) => {
  //   window.open(link, "_blank");
  // };

  return (
    <>
      <section
        className="our-feature-software"
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="title-our-features">
            <h3>Our Features</h3>
            <p>
              "Efficiently track student attendance, grades, and progress <br />{" "}
              while facilitating seamless communication between parents <br />{" "}
              and teachers through our intuitive platform."
            </p>
          </div>

          <div className="row" id="row">
            {features.map((feature, index) => (
              <div className="col-lg-4 mt-3" key={index} id="col-lg-4">
                <div className="card-features-software">
                  <center>
                    <img src={feature.image} alt={`feature${index + 1}`} />
                  </center>

                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>

                  <div className="feature-card-button">
                    <button onClick={() => handleRedirect(feature.link)}>
                      &#x2794;
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="row mt-4" id="row">
            {appFeatures.map((feature, index) => (
              <div className="col-lg-3" key={index} id="col-lg-3">
                <div className="app-features-software">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                  <div className="feature-card-button">
                    <button onClick={() => handleAppRedirect(appFeatures.link)}>
                      &#x2794;
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          <div className="download-section">
            {/* <div className="main-heading-container">
              <h2 className="heading-text">
                <img className="img-logo-barekaap" src={logo1} alt="Logo" />
                <span className="sub-heading-text">
                  Download of Our Mobile Application
                </span>
              </h2>
            </div> */}
            <div className="app-container">
              <div className="app-card">
                <h3 className="tittle-text">Barekaab Official App</h3>
                <div className="app-buttons">
                  <p>
                    <img className="icon-2" src={icon2} alt="icon" />
                    Student's
                  </p>
                  <p>
                    <img className="icon-1" src={icon1} alt="icon" />
                    Parent's
                  </p>
                </div>
                <p>
                  Barekaab offers customized solutions to streamline education,
                  enhancing collaboration and learning outcomes through
                  intuitive platforms and real-time communication.
                </p>
                <button className="download-btn">
                  <img className="icon-download" src={download} alt="icon" />
                  Download Now
                </button>
              </div>
              <div className="app-card">
                <h3 className="tittle-text">Barekaab Admin App</h3>
                <div className="app-buttons">
                  <p>
                    <img className="icon-2" src={icon2} alt="icon" />
                    Admin
                  </p>
                  |
                  <p>
                    <img className="icon-1" src={icon1} alt="icon" />
                    Teacher's
                  </p>
                  |
                  <p>
                    <img className="icon-1" src={icon1} alt="icon" />
                    Other Staff's
                  </p>
                </div>
                <p>
                  Barekaab offers customized solutions to streamline education,
                  enhancing collaboration and learning outcomes through
                  intuitive platforms and real-time communication.
                </p>
                <button className="download-btn">
                  <img className="icon-download" src={download} alt="icon" />
                  Download Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurFeatures;
