import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quote from "../../Assets/icon/quotes.webp";

const Testimonials = () => {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="home-testimonial-section">
        <div className="testimonials-title">
          <h3>Testimonials</h3>
          <h6>What our most client says!</h6>
        </div>

        <div className="slider-container">
          <Slider {...settings} className="testimonial-slider">
            <div className="testimonial-card">
              <div className="img-quotes">
                <img src={quote} alt="quote" />
              </div>
              <div className="content-test">
                <p>
                  Barekaab's financial management module has greatly simplified
                  our budgeting and accounting processes. It offers real-time
                  insights and analytics, empowering us to make informed
                  financial decisions.
                </p>

                <h3>David Wilson</h3>
                <h6>Finance Manager at EFG Institute</h6>
              </div>
            </div>
            <div className="testimonial-card">
              <div className="img-quotes">
                <img src={quote} alt="quote" />
              </div>
              <div className="content-test">
                <p>
                  From payroll management to staff recruitment, Barekaab has
                  automated our HR processes, saving us valuable time and
                  resources. It's a game-changer for any educational
                  institution.
                </p>

                <h3>Fatima Ali</h3>
                <h6>HR Manager at HIJ School System</h6>
              </div>
            </div>
            <div className="testimonial-card">
              <div className="img-quotes">
                <img src={quote} alt="quote" />
              </div>
              <div className="content-test">
                <p>
                  As a higher education institution, we needed a comprehensive
                  ERP solution that could handle the complexities of academic
                  management. Barekaab has exceeded our expectations, providing
                  seamless integration across departments.
                </p>

                <h3>Dr. Aisha Khan</h3>
                <h6>Dean of Faculty at DEF University</h6>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
