import React from "react";
import About from "../../Components/About/About";
import AboutCompany from "../../Components/About/AboutCompany";
import VisionCards from "../../Components/About/VisionCards";
import OurTeam from "../../Components/About/OurTeam";
import Partners from "../../Components/About/Partners";
import AdvanceBusiness from "../../Components/About/AdvanceBusiness";
import Facilities from "../../Components/About/Facilities";
import Careers from "../../Components/About/Careers";

const AboutUs = () => {
  return (
    <>
      <section className="page-wrapper">
        <About />
        <AboutCompany />
        <VisionCards />
        <OurTeam />
        <Partners />
        <AdvanceBusiness />
        <Facilities />
        <Careers />
      </section>
    </>
  );
};

export default AboutUs;
