import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const DataFAQ = () => {
  const faqsData = [
    {
      title: "Is your school ERP suits for our schools or not?",
      content:
        "Yes, our school ERP system is suitable for your schools. It simplifies enrollment and admission processes, reduces administrative costs, and enhances communication, making it beneficial for your school's needs.",
    },
    {
      title: "What is a School Management ERP?",
      content:
        "A School Management ERP (Enterprise Resource Planning) is a comprehensive software solution designed to streamline and automate various administrative and operational tasks within educational institutions",
    },
    {
      title: "How does a School Management ERP benefit schools?",
      content:
        "School Management ERPs offer numerous benefits including streamlined administrative processes, improved communication among stakeholders, enhanced student management, increased efficiency, data-driven decision-making, parent engagement, and better financial management.",
    },
    {
      title: "Is a School Management ERP user-friendly?",
      content:
        "Yes, just like using a smartphone or a tablet, School Management ERPs are designed to be easy to use. They have buttons and menus that are easy to understand, so teachers and staff can quickly learn how to use them.",
    },
    {
      title: "Can I use the school system from anywhere?",
      content:
        "Yes, you can! School Management ERPs are accessible from any device with an internet connection, like your computer, tablet, or smartphone. Whether you're at home or on the go, you can easily log in and use the system anytime, anywhere.",
    },
  ];
  const [active, setActive] = useState(`event-0`);

  return (
    <>
      <Accordion activeKey={active} className="accordion">
        {faqsData.map((faq, i) => (
          <div
            key={i}
            className={`accordion-card mb-15 ${
              active === `event-${i}` ? "accordion-active" : ""
            }`}
          >
            <div
              className="card-header"
              onClick={() =>
                setActive(active === `event-${i}` ? null : `event-${i}`)
              }
            >
              <Accordion.Item
                eventKey={`event-${i}`}
                style={{ cursor: "pointer" }}
              >
                <div className="header-content">
                  <h5>{faq.title}</h5>
                  {active === `event-${i}` ? (
                    <BsChevronUp className="icon up" />
                  ) : (
                    <BsChevronDown className="icon down" />
                  )}
                </div>
              </Accordion.Item>
            </div>
            <Accordion.Collapse eventKey={`event-${i}`}>
              <div className="card-body">
                {Array.isArray(faq.content) ? (
                  <ul>
                    {faq.content.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p>{faq.content}</p>
                )}
              </div>
            </Accordion.Collapse>
          </div>
        ))}
      </Accordion>
    </>
  );
};
export default DataFAQ;
