import React from 'react';
import SoftwareHero from '../../Components/Software/SoftwareHero';
import OurFeatures from '../../Components/Software/OurFeatures';

const Software = () => {
  return (
    <>
    <section className="software-section">
        <SoftwareHero />
        <OurFeatures />
    </section>
    </>
  )
}

export default Software;