import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Instance from "../../AxiosConfig";
import client1 from "../../Assets/icon/client-1.webp";
import client2 from "../../Assets/icon/client-2.webp";
import client3 from "../../Assets/icon/client-3.webp";
import client4 from "../../Assets/icon/client-4.webp";
import client5 from "../../Assets/icon/client-5.webp";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import country from "../../Assets/jsons/countryCityState.json";
import Swal from "sweetalert2";
import createUniqueS3FilenameWithDate, {
  handleFileUpload,
  showErrorAlert,
} from "../../Components/GlobalFunctions";
// import { HexColorPicker } from "react-colorful";

const Register = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const query = state?.query;
  const [trialPlan, setTrialPlan] = useState([]);
  const [stateListOptions, setStateListOptions] = useState([]);
  const [cityListOptions, setCityListOptions] = useState([]);
  const [errors, setErrors] = useState({});

  const [schoolLogo, setSchoolLogo] = useState({
    imageName: null,
    image: null,
    type: null,
    previewUrl: null,
  });

  const [formData, setFormData] = useState({
    schoolName: "",
    ownerFirstName: "",
    ownerLastName: "",
    schoolAddress: "",
    ownerEmail: "",
    schoolWebsite: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    subdomain: "",
    // schoolLogo: "",
    colorCode: "",
  });

  const fetchSubscriptionData = async () => {
    try {
      const url = `/superadmin/subscriptions`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        let data = response.data.subscriptions.filter(
          (sub) =>
            sub.planName.split(" ")[0].toString().toLowerCase() === "trial"
        );

        if (data.length >= 0) setTrialPlan(data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  useEffect(() => {
    if (formData.country) {
      const selectedCountry = country.Geo.find(
        (countryObj) => countryObj.name === formData.country.value
      );

      if (selectedCountry) {
        setStateListOptions(
          selectedCountry.states.map(({ name, cities }) => ({
            label: name,
            value: name,
            cities: cities,
          }))
        );
      } else {
        setStateListOptions([]);
      }
    } else {
      setStateListOptions([]);
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state) {
      const selectedState = stateListOptions.find(
        (stateObj) => stateObj.value === formData.state.value
      );

      if (selectedState) {
        setCityListOptions(
          selectedState.cities.map(({ name }) => ({
            label: name,
            value: name,
          }))
        );
      } else {
        setCityListOptions([]);
      }
    } else {
      setCityListOptions([]);
    }
  }, [formData.state, stateListOptions]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (
        !formData[key] &&
        key !== "subdomain" &&
        // key !== "schoolLogo" &&
        key !== "colorCode"
      ) {
        newErrors[key] = `${
          key.charAt(0).toUpperCase() +
          key
            .slice(1)
            .replace(/([A-Z])/g, " $1")
            .trim()
        } is required`;
      }
    });

    if (!mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (!validateForm()) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please fill in all required fields.",
        confirmButtonText: "Ok",
      });
      return;
    }
    setShowAdditionalFields(true);
  };

  const handleSelectImage = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setSchoolLogo({
        image: event.target.files[0],
        imageName: event.target.files[0].name,
        type: event.target.files[0].type,
        previewUrl: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (query) {
      if (!validateForm() || !schoolLogo.image) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please fill in all required fields.",
          confirmButtonText: "Ok",
        });
        return;
      }
    }

    let fileName = "";
    if (schoolLogo.image) {
      fileName = schoolLogo?.image
        ? createUniqueS3FilenameWithDate(schoolLogo?.imageName)
        : null;

      if (fileName) {
        let res = await handleFileUpload(fileName, schoolLogo);
        if (!res) {
          showErrorAlert("An error occured while uploading image");
          return;
        }
      }
    }

    try {
      const reqData = {
        schoolName: formData.schoolName,
        schoolAddress: formData.schoolAddress,
        schoolImage: fileName,
        ownerFirstName: formData.ownerFirstName,
        ownerLastName: formData.ownerLastName,
        ownerEmail: formData.ownerEmail,
        mobileNumber: mobileNumber,
        schoolWebsite: formData.schoolWebsite,
        city: formData.city?.value,
        country: formData.country?.value,
        planId: query?.selectdPlanId || trialPlan?._id,
        isYearly: query?.isYearly || false,
        zipcode: formData.zipcode,
        subdomain: formData.subdomain,
        colorCode: formData.colorCode,
      };
      const url = `/schools/createSchool/trialPlan`;
      const response = await Instance.post(url, reqData, {
        headers: {
          Authorization: `Bearer`,
        },
      });

      if (response.status === 201) {
        setFormData({
          schoolName: "",
          ownerFirstName: "",
          ownerLastName: "",
          schoolAddress: "",
          ownerEmail: "",
          schoolWebsite: "",
          country: "",
          state: "",
          city: "",
          zipcode: "",
          subdomain: "",
          // schoolLogo: "",
          colorCode: "",
        });
        setMobileNumber("");

        navigate("/thank-you");
      } else {
        console.log(response);
        Swal.fire({
          icon: "error",
          title: "Submission Error",
          text: "An error occurred while submitting the form. Please try again.",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Submission Error",
        text:
          error?.response?.data?.message ||
          error?.message ||
          "An error occurred while submitting the form. Please try again.",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      <section className="register-section">
        <div className="content-reg-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content-register-from">
                  <h3>Start Your Free Trial Now!</h3>
                  <ul>
                    <li>
                      Access all features of Barekaab Institutions Management
                      ERP Solutions.
                    </li>
                    <li>
                      No credit card required - sign up with just your email.
                    </li>
                    <li>
                      Receive dedicated support throughout your trial period.
                    </li>
                  </ul>
                </div>
                <hr />
                <div className="partners-icon-trial">
                  <div className="container">
                    <h3>TRUSTED BY INDUSTRY LEADERS</h3>

                    <div className="partners-logo-free">
                      <div className="form-marquee">
                        <div className="marquee-form-container">
                          <div className="content-marquee-free">
                            <img src={client1} alt="client1" />
                          </div>
                          <div className="content-marquee-free">
                            <img src={client2} alt="client2" />
                          </div>
                          <div className="content-marquee-free">
                            <img src={client3} alt="client3" />
                          </div>
                          <div className="content-marquee-free">
                            <img src={client4} alt="client4" />
                          </div>
                          <div className="content-marquee-free">
                            <img src={client5} alt="client5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card-free-trial-form">
                  <div className="form-free-title">
                    <h3 className="mb-3">
                      Start Institution{" "}
                      {query?.selectedPlan
                        ? "with " + query?.selectedPlan + " plan"
                        : "Free"}
                      !
                    </h3>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="social-login-buttons">
                      {/* <button className="google-button">
                        <AiFillGoogleCircle /> Sign in with Google
                      </button>
                      <button className="apple-button">
                        <SiApple /> Sign in with Apple ID
                      </button> */}
                    </div>
                    <p className="required-fields-notice">
                      * All fields are required
                    </p>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="School Name"
                        name="schoolName"
                        value={formData.schoolName}
                        onChange={handleChange}
                      />
                      {errors.schoolName && (
                        <span className="error-message">
                          {errors.schoolName}
                        </span>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Owner First Name"
                            name="ownerFirstName"
                            value={formData.ownerFirstName}
                            onChange={handleChange}
                          />
                          {errors.ownerFirstName && (
                            <span className="error-message">
                              {errors.ownerFirstName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Owner Last Name"
                            name="ownerLastName"
                            value={formData.ownerLastName}
                            onChange={handleChange}
                          />
                          {errors.ownerLastName && (
                            <span className="error-message">
                              {errors.ownerLastName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Owner Email"
                        name="ownerEmail"
                        value={formData.ownerEmail}
                        onChange={handleChange}
                      />
                      {errors.ownerEmail && (
                        <span className="error-message">
                          {errors.ownerEmail}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="School Address"
                        name="schoolAddress"
                        value={formData.schoolAddress}
                        onChange={handleChange}
                      />
                      {errors.schoolAddress && (
                        <span className="error-message">
                          {errors.schoolAddress}
                        </span>
                      )}
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6 mb-2">
                        <div className="form-group">
                          <Select
                            placeholder="Select Country"
                            options={country.Geo.map((country) => ({
                              label: country.name,
                              value: country.name,
                            }))}
                            value={formData.country}
                            onChange={(e) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                country: e,
                              }));
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                country: "",
                              }));
                            }}
                          />
                          {errors.country && (
                            <span className="error-message">
                              {errors.country}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="form-group">
                          <Select
                            placeholder="Select State"
                            options={stateListOptions}
                            name="state"
                            value={formData.state}
                            isDisabled={!formData.country}
                            onChange={(e) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                state: e,
                              }));
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                state: "",
                              }));
                            }}
                          />
                          {errors.state && (
                            <span className="error-message">
                              {errors.state}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <div className="form-group">
                          <Select
                            placeholder="Select City"
                            options={cityListOptions}
                            value={formData.city}
                            name="city"
                            isDisabled={!formData.state}
                            onChange={(e) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                city: e,
                              }));
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                city: "",
                              }));
                            }}
                          />
                          {errors.city && (
                            <span className="error-message">{errors.city}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="ZipCode"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={handleChange}
                          />
                          {errors.zipcode && (
                            <span className="error-message">
                              {errors.zipcode}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="School Website"
                        name="schoolWebsite"
                        value={formData.schoolWebsite}
                        onChange={handleChange}
                      />
                      {errors.schoolWebsite && (
                        <span className="error-message">
                          {errors.schoolWebsite}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <PhoneInput
                        className="mb-4"
                        style={{ width: "100%" }}
                        inputStyle={{ width: "100%" }}
                        defaultCountry="so"
                        value={mobileNumber}
                        onChange={(phone) => {
                          setMobileNumber(phone);
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            mobileNumber: "",
                          }));
                        }}
                      />
                      {errors.mobileNumber && (
                        <span className="error-message">
                          {errors.mobileNumber}
                        </span>
                      )}
                    </div>

                    {query ? (
                      !showAdditionalFields ? (
                        <div className="col-md-12 form-group text-center">
                          <p>
                            By signing up, you agree to our{" "}
                            <Link to="/">Terms of Service</Link> and acknowledge
                            that we will process your personal data in
                            accordance with our{" "}
                            <Link to="/">Privacy Notice</Link>.
                          </p>
                          <button
                            type="submit"
                            className="free-trail-button"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="col-md-12 form-group">
                            <div className="d-flex align-items-center ">
                              <span
                                className="input-group-text"
                                style={{ marginBottom: "24px" }}
                              >
                                https://
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Provide Sub Domain"
                                value={formData.subdomain}
                                name="subdomain"
                                onChange={handleChange}
                              />
                              <span
                                className="input-group-text"
                                style={{ marginBottom: "24px" }}
                              >
                                .school.barekaab.com
                              </span>
                            </div>
                            {errors.subdomain && (
                              <span className="error">{errors.subdomain}</span>
                            )}
                          </div>
                          <div className="col-md-12 form-group mt-2 mb-4">
                            <div className="form-group d-flex align-items-center">
                              <input
                                type="file"
                                id="file"
                                style={{ display: "none" }}
                                onChange={handleSelectImage}
                              />
                              <label
                                style={{
                                  width: "20%",
                                }}
                              >
                                Upload Logo (3X/1X)
                              </label>
                              <div style={{ width: "80%" }}>
                                {schoolLogo.previewUrl ? (
                                  <img
                                    src={schoolLogo.previewUrl}
                                    style={{
                                      height: "100px",
                                      width: "100%",
                                      overflow: "hidden",
                                      objectFit: "contain",
                                    }}
                                    alt="Uploaded"
                                  />
                                ) : (
                                  <label
                                    className="border p-4 rounded d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "100%",
                                      color: "gray",
                                      borderStyle: "dashed",
                                      cursor: "pointer",
                                    }}
                                    htmlFor="file"
                                  >
                                    Upload
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 form-group">
                            <div className="color-input-container d-flex align-items-center gap-2">
                              <input
                                type="text"
                                // readOnly
                                style={{ height: "36px" }}
                                onChange={(color) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    colorCode: color.target.value,
                                  }))
                                }
                                value={formData.colorCode || "#000000"}
                              />
                              <input
                                type="color"
                                style={{ height: "36px" }}
                                onChange={(color) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    colorCode: color.target.value,
                                  }))
                                }
                                value={formData.colorCode || "#000000"}
                              />
                            </div>
                            {errors.colorCode && (
                              <span className="error">{errors.colorCode}</span>
                            )}
                          </div>
                          <div className="col-md-12 form-group text-center">
                            <p>
                              By signing up, you agree to our{" "}
                              <Link to="/">Terms of Service</Link> and
                              acknowledge that we will process your personal
                              data in accordance with our{" "}
                              <Link to="/">Privacy Notice</Link>.
                            </p>
                            <button type="submit" className="free-trail-button">
                              Start {query?.selectedPlan} Plan
                            </button>
                          </div>
                        </>
                      )
                    ) : (
                      // )
                      <button className="free-trail-button" type="submit">
                        Start{" "}
                        {query?.selectedPlan
                          ? "with " + query?.selectedPlan + " plan"
                          : "Free Trial"}
                      </button>
                    )}
                  </form>
                  <p className="text-center">
                    Already have an account?{" "}
                    <Link style={{ textDecoration: "none" }} to="/login">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
