import React from "react";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import solution from "../../Assets/hero/solutions.webp";

const SolutionHero = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <>
      <section className="solution-section">
        <div style={{ position: "relative" }}>
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "#fff",
                },
              },
              fpsLimit: 500,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#136ad5",
                },
                links: {
                  color: "#136ad5",
                  distance: 80,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 6,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 120,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div className="container">
          <div className="solution-content">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="solution-content-grid">
                  <h3>Our Solutions</h3>
                  <p>
                    Barekaab offers customized solutions to streamline
                    education, enhancing collaboration and learning outcomes
                    through intuitive platforms and real-time communication.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="solution-img-grid">
                  <img src={solution} alt="solution" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SolutionHero;
