import React from 'react';
import DataFAQ from './DataFAQ';

const FAQ = () => {
  return (
    <>
    <section className="home-faq-section">
        <div className="container">
            <div className="FAQ-title">
                <h3>FAQs</h3>
                <small>Frequently asked questions?</small>
            </div>
            <DataFAQ />
        </div>
    </section>
    </>
  )
}

export default FAQ;