import React from "react";
import institutes from '../../Assets/icon/institutes.webp';
import teachers from '../../Assets/icon/teachers.webp';
import students from '../../Assets/icon/students.webp';

const MarqueeSection = ({ iconSrc, altText, title, count }) => {
  return (
    <div className="col-lg-4">
      <div className="marquee-section">
        <div className="marquee-icons">
          <img src={iconSrc} alt={altText} />
        </div>
        <div className="marquee-content">
          <h3>{title}</h3>
          <h6>{count}</h6>
        </div>
      </div>
    </div>
  );
};

const Marquee = () => {
  const marqueeData = [
    {
      iconSrc: institutes,
      altText: "institutes",
      title: "Institutions",
      count: "1000 +",
    },
    {
      iconSrc: teachers,
      altText: "teachers",
      title: "Teachers",
      count: "5000 +",
    },
    {
      iconSrc: students,
      altText: "students",
      title: "Students",
      count: "10000 +",
    },
  ];

  return (
    <section className="home-hero-marquee">
      <div className="container">
        <div className="row">
          {marqueeData.map((item, index) => (
            <MarqueeSection key={index} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Marquee;
