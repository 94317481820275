import React, { useState } from "react";
import call from "../../Assets/icon/PhoneCall.webp";
import email from "../../Assets/icon/EnvelopeSimple.webp";
import linkedIn from "../../Assets/icon/linkedIn.png";
import faceBook from "../../Assets/icon/facebook.png";
import X from "../../Assets/icon/twitter-x.png";
import instagram from "../../Assets/icon/instagram.png";
import user from "../../Assets/icon/User.webp";
import file from "../../Assets/icon/file.webp";

const CustomInput = ({ icon, placeholder, type }) => {
  const [fileLabel, setFileLabel] = useState("Upload CV");

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFileLabel(e.target.files[0].name);
    } else {
      setFileLabel("Upload CV");
    }
  };

  const filePlaceholder = type === "file" ? fileLabel : placeholder;

  return (
    <div className="custom-input">
      <div className="input-icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="vertical-line"></div>
      <div className="input-field">
        {type === "file" ? (
          <>
            <input
              type={type}
              id="file-upload"
              className="file-upload-input"
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload" className="file-upload-label">
              {filePlaceholder}
            </label>
          </>
        ) : (
          <input type={type} placeholder={placeholder} />
        )}
      </div>
    </div>
  );
};

const Careers = () => {
  return (
    <>
      <section className="careers-section">
        <div className="container">
          <div className="careers-page">
            <h3>Careers</h3>
            <p>
              Explore rewarding careers with opportunities for growth,
              development, and success. Join us and embark on a journey of
              fulfillment, challenge, and innovation.
            </p>

            <div className="row">
              <div className="col-lg-6">
                <div className="social-content-career">
                  <div className="icons-career">
                    <div className="call-icons-career">
                      <div className="call-icon">
                        <img src={call} alt="call" />
                      </div>
                      <div className="call-content">
                        <span>+252 5489774 598754</span>
                      </div>
                    </div>

                    <div className="call-icons-career">
                      <div className="call-icon">
                        <img src={email} alt="call" />
                      </div>
                      <div className="call-content">
                        <span>support@barekaab.com</span>
                      </div>
                    </div>

                    <div className="connect-us">
                      <h3>Connect With Us</h3>
                      <div className="connect-with-us">
                        <div className="icons-social-career">
                          <img src={linkedIn} alt="linkedIn" />
                        </div>
                        <div className="icons-social-career fb-icons-career">
                          <img src={faceBook} alt="faceBook" />
                        </div>
                        <div className="icons-social-career">
                          <img src={X} alt="X" />
                        </div>
                        <div className="icons-social-career">
                          <img src={instagram} alt="instagram" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="career-form">
                  <CustomInput
                    icon={user}
                    type="text"
                    placeholder="Full Name"
                  />
                  <CustomInput icon={email} type="email" placeholder="Email" />
                  <CustomInput
                    icon={call}
                    type="tel"
                    placeholder="Mobile No."
                  />
                  <CustomInput
                    icon={file}
                    type="file"
                    placeholder="Upload CV"
                  />

                  <button type="submit">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
