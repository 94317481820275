import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Spin } from "antd";
import { useState, useEffect } from "react";
import HomePage from "./Page/Home/index";
import AboutUs from "./Page/About/index";
import ContactUs from "./Page/Contact/index";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import Register from "./Page/Register/Register";
import ScrollTop from "./Components/ScrollTop";
import Pricing from "./Page/Pricing/Pricing";
import Software from "./Page/Software/Software";
import Solution from "./Page/Solution/Solution";
import Login from "./Page/Login/Login";
import ThankYou from "./Page/ThankYou/ThankYou";
import Privacy from "./Page/Privacy";
import Terms from "./Page/Terms";

function withLoadingSpinner(Component) {
  return function WithLoadingSpinner(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <Spin spinning={loading} size="large">
        <Component {...props} />
      </Spin>
    );
  };
}

const HomePageWithLoading = withLoadingSpinner(HomePage);
const AboutUsWithLoading = withLoadingSpinner(AboutUs);
const ContactUsWithLoading = withLoadingSpinner(ContactUs);
const RegisterWithLoading = withLoadingSpinner(Register);
const PricingWithLoading = withLoadingSpinner(Pricing);
const SoftwareWithLoading = withLoadingSpinner(Software);
const SolutionWithLoading = withLoadingSpinner(Solution);
const LoginWithLoading = withLoadingSpinner(Login);
const ThankYouWithLoading = withLoadingSpinner(ThankYou);
const PrivacyWithLoading = withLoadingSpinner(Privacy);
const TermsWithLoading = withLoadingSpinner(Terms);

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <ScrollTop />
        <Routes>
          <Route path="/" element={<HomePageWithLoading />} />
          <Route path="/about" element={<AboutUsWithLoading />} />
          <Route path="/contact" element={<ContactUsWithLoading />} />
          <Route path="/register" element={<RegisterWithLoading />} />
          <Route path="/pricing" element={<PricingWithLoading />} />
          <Route path="/software" element={<SoftwareWithLoading />} />
          <Route path="/solution" element={<SolutionWithLoading />} />
          <Route path="/login" element={<LoginWithLoading />} />
          <Route path="/thank-you" element={<ThankYouWithLoading />} />
          <Route path="/privacy" element={<PrivacyWithLoading />} />
          <Route path="/terms" element={<TermsWithLoading />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
