import React, { useEffect, useState } from "react";
import Instance from "../../AxiosConfig";
import { useNavigate } from "react-router-dom";

const PricingCard = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [isYearly, setIsYearly] = useState(false);
  const [, setAlertSeverity] = useState("success");
  const [, setSnackbarMessage] = useState("");
  const [pricingPlans, setPricingPlans] = useState([]);

  const navigate = useNavigate();

  const fetchSubscriptionData = async () => {
    try {
      const url = `/superadmin/subscriptions`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        const data = response.data.subscriptions.filter(
          (sub) =>
            sub.planName.split(" ")[0].toString().toLowerCase() !== "trial"
        );

        setPricingPlans(data);
      } else {
        setAlertSeverity("error");
        setSnackbarMessage("Something went wrong!");
      }
    } catch (error) {
      setAlertSeverity("error");
      setSnackbarMessage(error?.response?.error || "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const handleCardHover = (index) => {
    setActiveIndex(index);
  };

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  const currencyOptions = [
    { label: "US Dollar", value: "USD", sign: "$" },
    { label: "Euro", value: "EUR", sign: "€" },
    { label: "British Pound", value: "GBP", sign: "£" },
    { label: "Japanese Yen", value: "JPY", sign: "¥" },
    { label: "Australian Dollar", value: "AUD", sign: "A$" },
    { label: "Canadian Dollar", value: "CAD", sign: "C$" },
    { label: "Swiss Franc", value: "CHF", sign: "CHF" },
    { label: "Chinese Yuan", value: "CNY", sign: "¥" },
    { label: "Swedish Krona", value: "SEK", sign: "kr" },
    { label: "New Zealand Dollar", value: "NZD", sign: "NZ$" },
    { label: "Mexican Peso", value: "MXN", sign: "Mex$" },
    { label: "Singapore Dollar", value: "SGD", sign: "S$" },
    { label: "Hong Kong Dollar", value: "HKD", sign: "HK$" },
    { label: "Norwegian Krone", value: "NOK", sign: "kr" },
    { label: "South Korean Won", value: "KRW", sign: "₩" },
    { label: "Turkish Lira", value: "TRY", sign: "₺" },
    { label: "Russian Ruble", value: "RUB", sign: "₽" },
    { label: "Indian Rupee", value: "INR", sign: "₹" },
    { label: "Brazilian Real", value: "BRL", sign: "R$" },
    { label: "South African Rand", value: "ZAR", sign: "R" },
  ];

  return (
    <section className="pricing-card-section">
      <div className="container">
        <div className="pricing-card-title price-card">
          <h3>Pricing Plans</h3>
          <p>
            Unlock Your Potential with Our Flexible Pricing Plans <br />
            Find the Perfect Fit for Your Needs and Budget Today.
          </p>
        </div>

        <center>
          <div className="label-swicher">
            <p>Monthly</p>
            <label className="switch">
              <input
                type="checkbox"
                checked={isYearly}
                onChange={handleToggle}
              />
              <span className="slider round"></span>
            </label>
            <p>Yearly</p>
          </div>
        </center>

        <div className="pricing-cards">
          {pricingPlans.map((plan, index) => (
            <div
              className={`pricing-card ${
                activeIndex === index ? "active" : ""
              }`}
              key={index}
              onMouseEnter={() => handleCardHover(index)}
              style={{ height: "fit-content" }}
            >
              <div className="card-price-plan">
                <h3 className="card-title-price">{plan.planName}</h3>
                <hr className="first" />
                <p className="card-price">
                  <span>
                    {currencyOptions.filter(
                      (res) => res.value === plan.priceCurrency
                    )[0].sign || currencyOptions[0].sign}
                  </span>
                  {isYearly ? plan.yearlyPrice : plan.monthlyPrice}
                </p>
                <p className="card-portfolio">{plan.adminCount} Admins</p>
                <p className="card-portfolio">{plan.hrCount} HRs</p>
                <p className="card-portfolio">
                  {plan.librarianCount} Librarians
                </p>
                <p className="card-portfolio">{plan.teacherCount} Teachers</p>
                <p className="card-portfolio">{plan.studentCount} Students</p>

                <h3 className="feature-title">Core Features</h3>
                <ul className="features">
                  {plan.schoolErpAllowed && (
                    <li>
                      <span>&#10003;</span> &nbsp;&nbsp;{"School ERP"}
                    </li>
                  )}
                  {plan.websiteAllowed && (
                    <li>
                      <span>&#10003;</span> &nbsp;&nbsp;{"Website"}
                    </li>
                  )}
                  {plan.adminAppAllowed && (
                    <li>
                      <span>&#10003;</span> &nbsp;&nbsp;{"Admin App"}
                    </li>
                  )}
                  {plan.teacherAppAllowed && (
                    <li>
                      <span>&#10003;</span> &nbsp;&nbsp;{"Teacher App"}
                    </li>
                  )}
                  {plan.parentAppAllowed && (
                    <li>
                      <span>&#10003;</span> &nbsp;&nbsp;{"Parent App"}
                    </li>
                  )}
                  {plan.driverAppAllowed && (
                    <li>
                      <span>&#10003;</span> &nbsp;&nbsp;{"Driver App"}
                    </li>
                  )}
                  {plan.studentAppAllowed && (
                    <li>
                      <span>&#10003;</span> &nbsp;&nbsp;{"Student App"}
                    </li>
                  )}
                </ul>
                <hr className="second" />
                <button
                  onClick={() => {
                    navigate("/register", {
                      state: {
                        query: {
                          selectdPlanId: plan._id,
                          selectedPlan: plan.planName,
                          isYearly: isYearly,
                        },
                      },
                    });
                  }}
                  className="card-btn"
                  type="button"
                >
                  get started
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingCard;
