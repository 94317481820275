import React, { useEffect, useState } from "react";
import mission from "../../Assets/icon/mission.webp";
import vision from "../../Assets/icon/vision.webp";
import values from "../../Assets/icon/values.webp";

const VisionCards = () => {
  const [studentCount, setStudentCount] = useState(0);
  const [schoolCount, setSchoolCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [teamCount, setTeamCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (studentCount < 10) {
        setStudentCount(studentCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [studentCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (schoolCount < 9) {
        setSchoolCount(schoolCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [schoolCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userCount < 240) {
        setUserCount(userCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [userCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (teamCount < 2) {
        setTeamCount(teamCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [teamCount]);

  return (
    <>
      <section className="vision-cards">
        <div className="container">
          <div className="vision-card-grids">
            <div className="row">
              <div className="col-lg-4">
                <div className="card-grid-vision">
                  <img src={mission} alt="mission" />
                  <h3>Our Mission</h3>
                  <p>
                    "Our mission Empower learning through innovation and
                    collaboration."
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-grid-vision">
                  <img src={vision} alt="mission" />
                  <h3>Our Vision</h3>
                  <p>
                    "Our vision is to use new technology to make learning easy
                    and available for everyone."
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-grid-vision">
                  <img src={values} alt="mission" />
                  <h3>Our Values</h3>
                  <p>
                    Barekaab values doing great work with honesty, creativity,
                    teamwork, and growth.
                  </p>
                </div>
              </div>
            </div>

            <div className="row mt-4" id="row">
              <div className="col-lg-3" id="col-lg-3">
                <div className="visionary-card">
                  <h3>{studentCount}k+</h3>
                  <p>Students</p>
                </div>
              </div>
              <div className="col-lg-3" id="col-lg-3">
                <div className="visionary-card">
                  <h3>{schoolCount}k+</h3>
                  <p>Trusted Schools</p>
                </div>
              </div>
              <div className="col-lg-3" id="col-lg-3">
                <div className="visionary-card">
                  <h3>{userCount}k+</h3>
                  <p>New Users Joined Barekaab</p>
                </div>
              </div>
              <div className="col-lg-3" id="col-lg-3">
                <div className="visionary-card">
                  <h3>{teamCount}k+</h3>
                  <p>Teams Used Barekaab</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisionCards;
