import React from 'react';
import SolutionHero from '../../Components/Solution/SolutionHero';
import SolutionInstitutes from '../../Components/Solution/SolutionInstitutes';
import WebModules from '../../Components/Solution/WebModules';
import MobileApp from '../../Components/Solution/MobileApp';
import AppModules from '../../Components/Solution/AppModules';
import Portfolio from '../../Components/Solution/Portfolio';

const Solution = () => {
  return (
    <>
    <section className="page-wrapper">
        <SolutionHero />
        <SolutionInstitutes />
        <WebModules />
        <MobileApp />
        <AppModules />
        <Portfolio />
    </section>
    </>
  )
}

export default Solution;