import React from "react";
import school from "../../Assets/hero/school.webp";
import college from "../../Assets/hero/college.webp";
import university from "../../Assets/hero/university.webp";
import web from "../../Assets/hero/web.webp";

const SolutionInstitutes = () => {
  const institutesData = [
    {
      image: school,
      title: "For Schools",
      description:
        "Barekaab offers schools customized solutions to streamline operations, enhance communication, and improve educational outcomes.",
    },
    {
      image: college,
      title: "For Colleges",
      description:
        "Barekaab provides colleges with customized solutions to streamline operations, improve communication, and enhance learning outcomes.",
    },
    {
      image: university,
      title: "For University",
      description:
        "Barekaab offers universities tailored solutions to streamline operations, enhance communication, and improve educational outcomes.",
    },
  ];

  return (
    <>
      <section className="solution-institute">
        <div className="container">
          <div className="row">
            {institutesData.map((institute, index) => (
              <div className="col-lg-4" key={index}>
                <div className="solution-institute-card">
                  <img src={institute.image} alt={institute.title} />

                  <h3>{institute.title}</h3>
                  <p>{institute.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="row">
            <div className="instituteMac">
              <h3>Our Application</h3>
              <img src={web} alt="web" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SolutionInstitutes;
