import React from "react";
import portfolio from "../../Assets/hero/portfolio.webp";
import { useNavigate } from "react-router-dom";
const Portfolio = () => {
  const navigate = useNavigate();
  const goRegister = () => {
    navigate("/register");
  };
  return (
    <>
      <section className="portfolio-section">
        <div className="container">
          <div className="portfolio-content">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content-portfolio-grid">
                  <h3>Get your Portfolio website</h3>
                  <p>
                    "Showcase your work with a professional portfolio website.
                    Display your skills, projects, and achievements to attract
                    clients and opportunities. Create a stunning online presence
                    to stand out in your field and elevate your career."
                  </p>
                  <button onClick={goRegister}>Start Now!</button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="img-portfolio-grid">
                  <img src={portfolio} alt="portfolio" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
