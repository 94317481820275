import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AiFillGoogleCircle,
  AiFillEye,
  AiFillEyeInvisible,
} from "react-icons/ai";
import { SiApple } from "react-icons/si";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email, password });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <section className="login-section">
        <div className="container">
          <center>
            <div className="login-form">
              <div className="form-free-title">
                <h3>Login @Barekaab!</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="social-login-buttons">
                  <button className="google-button">
                     Sign in with <AiFillGoogleCircle style={{ fontSize: "24px" }}/>
                  </button>
                  <button className="apple-button">
                     Sign in with <SiApple style={{ fontSize: "24px" }}/>
                  </button>
                </div>
                <div className="email-input">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="password-container my-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div
                    className="password-toggle"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </div>
                </div>
                <button className="free-trail-button" type="submit">
                  Login
                </button>
              </form>
              <p className="text-center mt-3">
                Want to open an account?{" "}
                <Link style={{ textDecoration: "none" }} to="/register">
                  Register
                </Link>
              </p>
            </div>
          </center>
        </div>
      </section>
    </>
  );
};

export default Login;
