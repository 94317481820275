import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import macbook from "../../Assets/hero/mackbook.webp";
import { useNavigate } from "react-router-dom";

const SoftwareHero = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const navigate = useNavigate();
  const goSolution = () => {
    navigate("/solution");
  };
  return (
    <>
      <section className="software-Banner-section">
        <div style={{ position: "relative" }}>
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "#fff",
                },
              },
              fpsLimit: 200,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#136ad5",
                },
                links: {
                  color: "#136ad5",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 6,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 120,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "triangle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-banner-section">
                <h3>Software</h3>
                <p>Explore Our School ERP Software Demo Today.</p>

                <h6>
                  Experience a new era of school management efficiency with our
                  ERP system, offering dedicated apps for students, parents, and
                  administrators, all connected through a centralized dashboard.
                </h6>

                <button onClick={goSolution}>EXPLORE</button>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-banner-section">
                <img src={macbook} alt="macbook" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SoftwareHero;
