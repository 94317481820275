import React, { useState } from "react";
import tab1 from "../../Assets/icon/marquee-1.webp";
import tab2 from "../../Assets/icon/marquee-2.webp";
import tab3 from "../../Assets/icon/marquee-3.webp";
import tab4 from "../../Assets/icon/marquee-4.webp";
import tab5 from "../../Assets/icon/marquee-5.webp";
import academics1 from "../../Assets/icon/academics-1.webp";
import academics2 from "../../Assets/icon/academics-2.webp";
import academics3 from "../../Assets/icon/academics-3.webp";
import academics4 from "../../Assets/icon/academics-4.webp";
import academics5 from "../../Assets/icon/academics-5.webp";
import academics6 from "../../Assets/icon/academics-6.webp";
import st1 from "../../Assets/icon/st-1.webp";
import st2 from "../../Assets/icon/st-2.webp";
import st3 from "../../Assets/icon/st-3.webp";
import st4 from "../../Assets/icon/st-4.webp";
import st5 from "../../Assets/icon/st-5.webp";
import st6 from "../../Assets/icon/st-6.webp";
import lib1 from "../../Assets/icon/lib-1.webp";
import lib2 from "../../Assets/icon/lib-2.webp";
import lib3 from "../../Assets/icon/lib-3.webp";
import lib4 from "../../Assets/icon/lib-4.webp";
import lib5 from "../../Assets/icon/lib-5.webp";
import lib6 from "../../Assets/icon/lib-6.webp";
import ex1 from "../../Assets/icon/ex-1.webp";
import ex2 from "../../Assets/icon/ex-2.webp";
import ex3 from "../../Assets/icon/ex-3.webp";
import fin1 from "../../Assets/icon/fin-1.webp";
import fin2 from "../../Assets/icon/fin-2.webp";
import fin3 from "../../Assets/icon/fin-3.webp";

const Features = () => {
  const [activeTab, setActiveTab] = useState("academics");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabData = {
    academics: [
      {
        title: "Class & Sections",
        content:
          "A dashboard show casing various classes and sections enhance productivity while minimizing power consumption.",
        img: academics1,
      },
      {
        title: "Syllabus",
        content:
          "Easily match teachers with the subjects they teach for each class, and let administrators handle subject assignments for each class without any hassle.",
        img: academics2,
      },
      {
        title: "Lesson scheduling",
        content:
          "Teachers use a phone app to take attendance for students, and parents can check daily attendance reports.",
        img: academics3,
      },

      {
        title: "Attendance",
        content:
          "Teachers use a phone app to mark if students are present or absent, and parents can check reports every day to see if their child attended school.",
        img: academics4,
      },
      {
        title: "Admissions",
        content:
          "Effectively manage student questions, make admission processes smoother, and easily sign up new students.",
        img: academics5,
      },
      {
        title: "Circular",
        content:
          "We'll use the Circular feature on the mobile app to share updates with parents, and we'll also send them notifications.",
        img: academics6,
      },
    ],

    students: [
      {
        title: "Assignment & Notes",
        content:
          "Teachers can use a phone app to give out homework and class notes, and also to check and grade what students do.",
        img: st1,
      },
      {
        title: "Time Table",
        content:
          "Our tool helps you easily make, change, and keep track of schedules for subjects, meals, and exams.",
        img: st2,
      },
      {
        title: "Student Tracking",
        content:
          "Teachers, parents, and administrators can easily view the status of a student's ID card. Tracking Student Progress",
        img: st3,
      },
      {
        title: "Email / Chat",
        content:
          "Our tool provides email and notification options to make communication smooth and easy.",
        img: st4,
      },
      {
        title: "Leave",
        content:
          "Our system effectively manages different types of leave, including part-time, sick, and occasional leaves, as well as holiday scheduling.",
        img: st5,
      },
      {
        title: "Task Management",
        content:
          "Teachers and school principals can give assignments about particular subjects and decide which ones are most important.",
        img: st6,
      },
    ],

    library: [
      {
        title: "Vehicle Tracking",
        content:
          "Effortlessly showcase and handle primary data categories using our tool. In brief: organize and control core data types seamlessly.",
        img: lib1,
      },
      {
        title: "Vehicle Details",
        content:
          "Our module displays a complete list with vehicle numbers, maximum seat capacity, insurance renewal dates, contact numbers, and driver information",
        img: lib2,
      },
      {
        title: "Route Details",
        content:
          "Our module gives you the route information for each area, along with the name of the driver",
        img: lib3,
      },
      {
        title: "Library ",
        content:
          "Make borrowing and returning books easier for teachers and students with our management system.",
        img: lib4,
      },
      {
        title: "Transport Fees",
        content:
          "Our system effectively handles organizing when students and employees are picked up and dropped off, as well as managing how fees are structured.",
        img: lib5,
      },
      {
        title: "Membership",
        content:
          "Check out the different kinds of plans where students can explore, like library or transportation membership.",
        img: lib6,
      },
    ],

    exam: [
      {
        title: "Online Examination",
        content:
          "Our tool makes tests for different subjects on the internet, and gives students their scores quickly.",
        img: ex1,
      },
      {
        title: "Question Bank",
        content:
          "Easily retrieve, add, and save questions from the question library using our tool.",
        img: ex2,
      },
      {
        title: "Exam Timetable",
        content:
          "Our module helps you easily organize and keep track of when your weekly, monthly, and yearly exams start and end.",
        img: ex3,
      },
    ],
    finance: [
      {
        title: "Pay Type",
        content:
          "Easily see and control how payment methods and names are entered with our module.",
        img: fin1,
      },
      {
        title: "Fees Management",
        content:
          "Our system handles school payments, generates fee receipts, and keeps the fee records up-to-date, making sure the data is very accurate.",
        img: fin2,
      },
      {
        title: "Account",
        content:
          "Keep an organized record of your bank and cash activities, like deposits and withdrawals, as well as your spending and receipts, using our system.",
        img: fin3,
      },
    ],
  };

  return (
    <>
      <section className="home-main-features">
        <div className="container">
          <div className="main-features-title">
            <h3>Main Features</h3>
            <p>Best features ever.</p>
          </div>
          <div className="tab-images">
            <div
              className={`tabs-buttons-image ${
                activeTab === "academics" ? "activeTabBackground" : ""
              }`}
            >
              <img
                src={tab1}
                alt="tab-1"
                onClick={() => handleTabClick("academics")}
                className={activeTab === "academics" ? "active" : ""}
                title="Academics"
              />
              <h6>Academics</h6>
            </div>

            <div
              className={`tabs-buttons-image ${
                activeTab === "students" ? "activeTabBackground" : ""
              }`}
            >
              <img
                src={tab2}
                alt="tab-2"
                onClick={() => handleTabClick("students")}
                className={activeTab === "students" ? "active" : ""}
                title="Students"
              />
              <h6>Students</h6>
            </div>

            <div
              className={`tabs-buttons-image ${
                activeTab === "library" ? "activeTabBackground" : ""
              }`}
            >
              <img
                src={tab3}
                alt="tab-3"
                onClick={() => handleTabClick("library")}
                className={activeTab === "library" ? "active" : ""}
                title="Linrary & Transport"
              />
              <h6>Library & Transport</h6>
            </div>

            <div
              className={`tabs-buttons-image ${
                activeTab === "exam" ? "activeTabBackground" : ""
              }`}
            >
              <img
                src={tab4}
                alt="tab-4"
                onClick={() => handleTabClick("exam")}
                className={activeTab === "exam" ? "active" : ""}
                title="Exams"
              />
              <h6>Exam</h6>
            </div>

            <div
              className={`tabs-buttons-image ${
                activeTab === "finance" ? "activeTabBackground" : ""
              }`}
            >
              <img
                src={tab5}
                alt="tab-5"
                onClick={() => handleTabClick("finance")}
                className={activeTab === "finance" ? "active" : ""}
                title="Finance"
              />
              <h6>Finance</h6>
            </div>
          </div>

          <div className="tab-content">
            <div className="row" id="row">
              {tabData[activeTab] instanceof Array ? (
                tabData[activeTab].map((item, index) => (
                  <div className="col-lg-4" key={index} id="col-lg-4">
                    <div className="tab-card">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="tabs-inside-img">
                            <img src={item.img} alt="" />
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="tabs-inside-content">
                            <h3>{item.title}</h3>
                            <p>{item.content}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-lg-12">
                  <div className="tab-card">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="tabs-inside-img">
                          <img src={tabData[activeTab].img} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="tabs-inside-content">
                          <h3>{tabData[activeTab].title}</h3>
                          <p>{tabData[activeTab].content}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
