import React, { useEffect, useState } from "react";
import shape from "../../Assets/icon/shape.webp";
import location from "../../Assets/icon/location.webp";
import email1 from "../../Assets/icon/email.webp";
import phone from "../../Assets/icon/phone.webp";
import Instance from "../../AxiosConfig";

const Contact = () => {
  const [data, setData] = useState(null);

  const fetchManageInstituteData = async () => {
    try {
      const response = await Instance.get(
        "contact/get/info"
        // , {
        // headers: {
        //   Authorization: `Bearer ${loggedInUserData.jwt_token}`,
        // },
        // }
      );
      // console.log(response.data);
      setData(response.data.contactInfo?.contactPageDetail);
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.schools &&
        error?.response?.data?.schools?.message
      ) {
        // setSnackbarMessage(error.response.data.schools.message);
      } else {
        // setSnackbarMessage("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    fetchManageInstituteData();
  }, []);

  return (
    <>
      <section className="contact-page">
        <div className="contact-us-container">
          <div className="content-contact">
            <h3>Contact Us</h3>
            <p>
              Thank you for your interest in Barekaab Institutional ERP
              Management System. <br /> We are here to assist you with any
              inquiries you may have. <br /> Please feel free to reach out to us
              using the contact information .
            </p>
          </div>
        </div>
        <div className="container-contact">
          <img src={shape} className="square" alt="" />
          <div className="form">
            <div className="contact-info">
              <h3 className="titltitle-contact-us-page">Let's get in touch</h3>
              <p className="text">
                We aim to respond to all inquiries within 24 hours. Thank you
                for choosing Barekaab Institutional ERP Management System.
              </p>

              <div className="info">
                <div className="information">
                  <img src={location} className="icon" alt="" />
                  <p>
                    {data?.location ||
                      "Nashad Mall #204 Digfeer Rd, Banadir Mogadishu, Somalia"}
                  </p>
                </div>
                <div className="information">
                  <img src={email1} className="icon" alt="" />
                  <p>{data?.email || "support@barekaab.com"}</p>
                </div>
                <div className="information">
                  <img src={phone} className="icon" alt="" />
                  <p>{data?.phoneNumber || "+252 61 0822156"}</p>
                </div>
              </div>

              <div className="social-media">
                <p>Connect with us :</p>
                <div className="social-icons">
                  <a href={data?.facebookLink} target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href={data?.instagramLink || "/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href={data?.twitterLink || "/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-x-twitter"></i>
                  </a>
                  <a
                    href={data?.linkedinLink || "/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <span className="circle one"></span>
              <span className="circle two"></span>

              <form
                className="form-contact"
                action="index.html"
                autocomplete="off"
              >
                <h3 className="title-contact-us-page">Contact us</h3>
                <div className="input-container">
                  <input
                    type="text"
                    name="name"
                    className="input"
                    placeholder="Username"
                  />
                </div>
                <div className="input-container">
                  <input
                    type="email"
                    name="email"
                    className="input"
                    placeholder="Email"
                  />
                </div>
                <div className="input-container">
                  <input
                    type="tel"
                    name="phone"
                    className="input"
                    placeholder="Phone"
                  />
                </div>
                <div className="input-container textarea">
                  <textarea
                    name="message"
                    className="input"
                    placeholder="Message"
                    rows={5}
                  ></textarea>
                </div>
                <input type="submit" value="Send" className="btn-contact-us" />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
