import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hero1 from "../../Assets/hero/hero-1.webp";
import hero2 from "../../Assets/hero/hero-2.webp";
import hero3 from "../../Assets/hero/hero-3.webp";
import { useNavigate } from "react-router-dom";

const slides = [
  {
    title: "Most Trusted Institution Management ERP",
    description:
      "Our user-friendly school app is designed with great features and interactive User Interface.",
    buttonText: "Try Free Trial",
    image: hero1,
  },
  {
    title: "The Ultimate Institution Management Solution",
    description: "Discover the comprehensive features of Barekaab EduHub, a state-of-the-art ERP solution tailored to meet the diverse needs of educational institutions.",
    buttonText: "Try Free Trial",
    image: hero2,
  },
  {
    title: "Empowering Educators, Enhancing Learning",
    description: "Transform your educational institution with Barekaab SchoolMaster, the trusted ERP solution that empowers educators and administrators alike.",
    buttonText: "Try Free Trial",
    image: hero3,
  },
];

const Hero = () => {

    const navigate = useNavigate();

    const goRegister = () =>{
        navigate('/register');
    }
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="home-hero-section">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="hero-content">
                      <h3>{slide.title}</h3>
                      <p>{slide.description}</p>
                      <button onClick={goRegister}>{slide.buttonText}</button>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="hero-img-content">
                      <img src={slide.image} alt={`slide-${index}`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
};

export default Hero;
