import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaRegWindowClose, FaSearch } from "react-icons/fa";
import logo from "../../Assets/logo/logo.webp";

const SearchPopup = ({ onClose }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = () => {
    console.log("Searching for:", searchQuery);
    onClose();
  };

  return (
    <>
      <div className="container">
        <div className="search-popup-title">
          <h3>Search for your Query...</h3>
        </div>
        <div className="search-popup">
          <input
            type="search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="form-control"
          />
          <button className="search-btn" onClick={handleSearch}>
            Search
          </button>
          <button className="close-icon" onClick={onClose}>
            <FaRegWindowClose />
          </button>
        </div>
      </div>
    </>
  );
};

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);

  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSearchToggle = () => {
    setIsSearchPopupOpen(!isSearchPopupOpen);
  };

  const handleRegister = () => {
    navigate("/register");
    setIsMenuOpen(!isMenuOpen);
  };
  const handleLogin = () => {
    window.open("https://school.barekaab.com/", "_blank");
  };

  const closeMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <header className={`header ${isMenuOpen ? "open" : ""}`}>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div
        className={`nav-toggle ${isMenuOpen ? "open" : ""}`}
        onClick={handleMenuToggle}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={closeMenu}>
              About
            </Link>
          </li>
          <li>
            <Link to="/software" onClick={closeMenu}>
              Software
            </Link>
          </li>
          <li>
            <Link to="/solution" onClick={closeMenu}>
              Solutions
            </Link>
          </li>
          <li>
            <Link to="/pricing" onClick={closeMenu}>
              Pricing
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMenu}>
              Contact
            </Link>
          </li>
          <li>
            <button className="login-btn" onClick={handleLogin}>
              Login
            </button>
          </li>

          <li>
            <button className="btn-header-login" onClick={handleRegister}>
              Register
            </button>
          </li>

          <li>
            <div className="search-popup-icon" onClick={handleSearchToggle}>
              <FaSearch />
            </div>
          </li>
        </ul>
        {isSearchPopupOpen && (
          <SearchPopup onClose={() => setIsSearchPopupOpen(false)} />
        )}
      </nav>
    </header>
  );
};

export default Header;
