import React from "react";
import module1 from "../../Assets/hero/AppMod-1.webp";
import module2 from "../../Assets/hero/AppMod-2.webp";
import module3 from "../../Assets/hero/AppMod-3.webp";
import module4 from "../../Assets/hero/AppMod-4.webp";
import module5 from "../../Assets/hero/AppMod-5.webp";
import module6 from "../../Assets/hero/AppMod-6.webp";
import module7 from "../../Assets/hero/AppMod-7.webp";
import module8 from "../../Assets/hero/AppMod-8.webp";

const modulesData = [
  {
    image: module1,
    title: "Assignments",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module2,
    title: "Attendance",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module3,
    title: "Resources",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module4,
    title: "Notifications",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module5,
    title: "Profile",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module6,
    title: "Team Support",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module7,
    title: "Library",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module8,
    title: "Events / Timetable",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
];

const AppModules = () => {
  return (
    <>
      <section className="module-section">
        <div className="container">
            <div className="module-title">
                <h3>Mobile Application Modules</h3>
            </div>
          <div className="module-content">
            <div className="row" id="row">
              {modulesData.map((module, index) => (
                <div className="col-lg-3" id="col-lg-3" key={index}>
                  <div className="module-content-grid">
                    <img src={module.image} alt={`module${index + 1}`} />
                    <h3>{module.title}</h3>
                    <p>{module.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppModules;
