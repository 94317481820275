import React from "react";
import aboutUs from "../../Assets/hero/about-us.webp";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  const goSoftware = () => {
    navigate("/software");
  };
  return (
    <>
      <section className="about-us-section">
        <div className="container">
          <div className="about-content-about">
            <div className="row">
              <div className="col-lg-6">
                <div className="content-about">
                  <h3>About Us</h3>
                  <p>
                    Barekaab is at the forefront of educational innovation,
                    dedicated to transforming the way people learn. With a
                    commitment to accessibility and enjoyment, we strive to make
                    learning a fulfilling experience for all, regardless of
                    background or circumstance.
                  </p>
                  <button onClick={goSoftware}>See More</button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-img-about">
                  <img src={aboutUs} alt="aboutUs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
