import React, { useState } from "react";

const PricingTable = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const pricingPlans = [
    {
      title: "Gold",
      price: 9.99,
      students: "0-500 Students",
      features: {
        "School ERP": true,
        "Payment Integration": true,
        Website: true,
        "Student Web Login": false,
        "Student Mobile App": false,
        "Teacher Mobile App": false,
        "Admin Mobile App": false,
        "Payment Mobile App": false,
        "Library Module": false,
      },
    },
    {
      title: "Platinum",
      price: 19.99,
      students: "500-1000 Students",
      features: {
        "School ERP": true,
        "Payment Integration": true,
        Website: true,
        "Student Web Login": true,
        "Student Mobile App": false,
        "Teacher Mobile App": false,
        "Admin Mobile App": false,
        "Payment Mobile App": false,
        "Library Module": false,
      },
    },
    {
      title: "Diamond",
      price: 49.99,
      students: "> 1000 Students",
      features: {
        "School ERP": true,
        "Payment Integration": true,
        Website: true,
        "Student Web Login": true,
        "Student Mobile App": true,
        "Teacher Mobile App": true,
        "Admin Mobile App": true,
        "Payment Mobile App": true,
        "Library Module": true,
      },
    },
  ];

  const handlePlanSelect = (index) => {
    setSelectedPlan(index);
  };

  return (
    <section className="pricing-table-section">
      <div className="container">
        <div className="pricing-card-title">
          <h3>
            Choose an annual <br /> membership
          </h3>
          <p>
            All membership plans come with a 30-day satisfaction <br />{" "}
            guarantee.
          </p>
        </div>

        <div className="pricing-table">
          <div className="feature-column">
            <div className="feature-header">Features</div>
            {Object.keys(pricingPlans[0].features).map((feature, index) => (
              <div className="feature-item" key={index}>
                {feature}
              </div>
            ))}
          </div>
          {pricingPlans.map((plan, index) => (
            <div
              className={`plan-column ${
                selectedPlan === index ? "selected" : ""
              }`}
              key={index}
              onClick={() => handlePlanSelect(index)}
            >
              <div className="plan-header">{plan.title}
              </div>
              {Object.values(plan.features).map((isActive, index) => (
                <div className="plan-item" key={index}>
                  {isActive ? (
                    <span className="check">&#10003;</span>
                  ) : (
                    <span className="cross">&#10005;</span>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingTable;
