import React from "react";
import module1 from "../../Assets/hero/module-1.webp";
import module2 from "../../Assets/hero/module-2.webp";
import module3 from "../../Assets/hero/module-3.webp";
import module4 from "../../Assets/hero/module-4.webp";
import module5 from "../../Assets/hero/module-5.webp";
import module6 from "../../Assets/hero/module-6.webp";
import module7 from "../../Assets/hero/module-7.webp";
import module8 from "../../Assets/hero/module-8.webp";
import solutionBtnBg from "../../Assets/hero/solution-btn-bg.webp";
import openBook from "../../Assets/hero/open-book.webp";
import { useNavigate } from "react-router-dom";
const modulesData = [
  {
    image: module1,
    title: "Student Information System",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module2,
    title: "Academic Management Module",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module3,
    title: "Examination Management Module",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module4,
    title: "Admission Management Module",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module5,
    title: "Course Management Module",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module6,
    title: "Library Management Module",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module7,
    title: "Attendance Tracking Module",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
  {
    image: module8,
    title: "Hostel Management Module",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae nulla diam in ac dictum a urna",
  },
];

const WebModules = () => {
  const navigate = useNavigate();
  const goContactUs = () => {
    navigate("/contact");
  };
  return (
    <>
      <section className="module-section">
        <div className="container">
          <div className="module-title">
            <h3>Web Application Modules</h3>
          </div>
          <div className="module-content">
            <div className="row" id="row">
              {modulesData.map((module, index) => (
                <div className="col-lg-3" id="col-lg-3" key={index}>
                  <div className="module-content-grid">
                    <img src={module.image} alt={`module${index + 1}`} />
                    <h3>{module.title}</h3>
                    <p>{module.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="web-module-solution">
            <div className="web-module-content">
              <h3>
                All you need is one <br />
                <span style={{ color: "#136ad5" }}> Solution.</span>
              </h3>

              <div className="d-flex align-items-center">
                <button onClick={goContactUs}>Let's Connect</button>

                <img src={solutionBtnBg} alt="solutionBtnBg" />
              </div>
            </div>
            <div className="web-module-img">
              <img src={openBook} alt="openBook" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WebModules;
