import React from "react";
import Faciliy1 from "../../Assets/hero/facilities-1.webp";
import Faciliy2 from "../../Assets/hero/facilities-2.webp";
import Faciliy3 from "../../Assets/hero/facilities-3.webp";

const Facilities = () => {
  return (
    <>
      <section className="facilities-section">
        <div className="container">
          <div className="facilities-title">
            <h3>Our Facilities</h3>
          </div>
          <div className="facilities-cards">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="card-facility">
                  <img src={Faciliy1} alt="Faciliy1" />
                  <div className="facility-overlay">
                    <div className="overlay-content">
                      <h3>Mobile App</h3>
                      <hr />
                      <p>
                        Discover the Power of Our Teacher Interface in
                        Revolutionizing Classroom Management.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-facility">
                  <img src={Faciliy2} alt="Faciliy2" />
                  <div className="facility-overlay">
                    <div className="overlay-content">
                      <h3>Admin</h3>
                      <hr />
                      <p>
                        Navigate Through Our Admin Interface for Streamlined
                        School Management
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-facility">
                  <img src={Faciliy3} alt="Faciliy3" />
                  <div className="facility-overlay">
                    <div className="overlay-content">
                      <h3>Desktop</h3>
                      <hr />
                      <p>
                        Navigate Through Our Admin Interface for Streamlined
                        School Management
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Facilities;
