import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import Instance from "../AxiosConfig";

const createUniqueS3FilenameWithDate = (filename) => {
  filename = filename.toString().replace(" ", "");
  const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
  const uniqueId = uuidv4();
  const ext = filename.split(".").pop();
  const baseName = filename.replace(`.${ext}`, "");
  const validBaseName = baseName.replace(/[^a-zA-Z0-9-_]/g, "_");
  const uniqueFilename = `${validBaseName}-${date}-${uniqueId}.${ext}`;
  return uniqueFilename;
};

export const showErrorAlert = (message) => {
  Swal.fire({
    position: "center",
    icon: "error",
    title: "Error",
    text: message,
    customClass: {
      icon: "centered-icon",
    },
  });
};
export const showSuccessAlert = (message) => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: "Successful",
    text: message,
    customClass: {
      icon: "centered-icon",
    },
  });
};

export const showConfirmationDialog = (title, message) => {
  return Swal.fire({
    title: title || "Are you sure?",
    text: message || "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    return result.isConfirmed;
  });
};

export const handleAllError = (error) => {
  if (error.message.startsWith("ValidationError")) {
    showErrorAlert(error.message.replace("ValidationError: ", ""));
  } else if (error.message.startsWith("FileUploadError")) {
    showErrorAlert(error.message.replace("FileUploadError: ", ""));
  } else if (error.response) {
    showErrorAlert(
      error.response.data?.error || "Server error, please try again later."
    );
  } else {
    showErrorAlert("An unexpected error occurred, please try again later.");
  }
};

export const handleFileUpload = async (fileName, file) => {
  try {
    const response = await Instance.get("/getUploadUrl", {
      params: {
        fileName: fileName,
        contentType: file.type,
      },
    });
    // console.log("Upload URL response:", response);
    const presignedUrl = response?.data?.url;

    await Instance.put(presignedUrl, file?.image || file?.file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return true;
  } catch (error) {
    console.error("File upload error:", error);
    return false;
  }
};

export const capitalizeFirstLetterOfEachWord = (s) => {
  const words = s.toString().split(" ");

  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(" ");
};

export default createUniqueS3FilenameWithDate;
