import React from "react";
import aboutComapny from "../../Assets/hero/about-company.webp";
import { useNavigate } from "react-router-dom";
const AboutCompany = () => {
  const navigate = useNavigate();
  const goContactUs = () => {
    navigate("/contact");
  };
  return (
    <>
      <section className="about-company-section">
        <div className="container">
          <div className="about-company">
            <div className="row">
              <div className="col-lg-6">
                <div className="content-img-about">
                  <img src={aboutComapny} alt="aboutComapny" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-about">
                  <h3>About Company</h3>
                  <p>
                    Barekaab's ERP system revolutionizes school management with
                    dedicated apps, streamlining processes and enhancing
                    communication for all stakeholders, ushering in an efficient
                    future for education.
                  </p>
                  <ol>
                    <li>Dedicated apps streamline communication.</li>
                    <li>Centralized dashboard offers real-time data.</li>
                    <li>Automation simplifies tasks.</li>
                    <li>Innovative tech transforms school management.</li>
                  </ol>
                  <button onClick={goContactUs}>Connect With Us</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCompany;
