import React from 'react';
import mac from "../../Assets/hero/mac.webp";
import apple from "../../Assets/icon/app-store.webp";
import google from "../../Assets/icon/google-play.webp";

const   OurProduct = () => {
    

    const handleGoogle = () =>{
        window.open('https://play.google.com/store/games?device=windows&pli=1', '_blank');
    }

    const handleApple = () =>{
        window.open('https://www.apple.com/in/app-store/', '_blank');
    }

  return (
    <>
    <section className="home-product-section">
        <div className="container">
            <div className="product-section">
                <h3>Our Product</h3>
                <h6>School app, parent app & Management app</h6>
                <p>Choose School Management Software for efficient administration, staff, and student management. Improve communication and streamline tasks like attendance and parcel tracking effortlessly.</p>
                <img src={mac} alt="macbook" />
            </div>

            <div className="download-app-buttons">
                <div className="google-play">
                    <img onClick={handleGoogle} style={{cursor:"pointer"}} src={google} alt="google-play" />
                </div>
                <div className="app-store">
                    <img onClick={handleApple} style={{cursor:"pointer"}} src={apple} alt="app-store" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default OurProduct