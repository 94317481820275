import React from 'react'

const Privacy = () => {
    return (
        <>
            <div className="container">
                <div className="privacy-card">
                    <div className="privacy-title">
                        <span>Privacy Policy</span>
                    </div>
                    <hr />
                    <div className="privacy-content">
                        <h5>Privacy Policy for Barekaab School Management ERP</h5>
                        <h6>Updated On: 13/06/2024</h6>

                        <br />
                        <p><strong>1. Introduction</strong></p>
                        <p>Welcome to Barekaab School Management ERP. Barekaab provides a comprehensive school management system that includes both web and mobile applications. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our services.</p>

                        <br />
                        <p><strong>2. Information We Collect</strong></p>
                        <p>We collect various types of information to provide and improve our services:</p>

                        <ul>
                            <p><strong>a. Personal Information:</strong></p>
                            <li>Students: Name, age, gender, contact details, academic records, attendance, health information, and behavioral records.</li>
                            <li>Parents: Name, contact details, and relationship to the student.</li>
                            <li>Teachers and Staff: Name, contact details, employment history, qualifications, and payroll information.</li>
                        </ul>

                        <ul>
                            <p><strong>b. Usage Information:</strong></p>
                            <li>Information about how you interact with our web and mobile applications, including IP addresses, device information, and browsing behavior.</li>
                        </ul>

                        <ul>
                            <p><strong>c. Location Information:</strong></p>
                            <li>We may collect location data to optimize transportation routes and enhance safety features.</li>
                        </ul>

                        <br />
                        <p><strong>3. How We Use Your Information</strong></p>
                        <p>We use the information we collect for the following purposes:</p>

                        <ul>
                            <p><strong>a. To Provide and Manage Services:</strong></p>
                            <li>Manage student records, attendance, grades, and other educational information.</li>
                            <li>Facilitate communication between students, parents, teachers, and administrative staff.</li>
                            <li>Process payments and manage financial records.</li>
                        </ul>

                        <ul>
                            <p><strong>b. To Improve Our Services:</strong></p>
                            <li>Analyze usage patterns to enhance user experience and functionality.</li>
                            <li>Develop new features and services.</li>
                        </ul>

                        <ul>
                            <p><strong>c. For Safety and Security:</strong></p>
                            <li>Monitor and ensure the safety and security of students, staff, and school property.</li>
                            <li>Implement safety protocols and emergency response measures.</li>
                        </ul>

                        <ul>
                            <p><strong>d. Legal Compliance:</strong></p>
                            <li>Comply with legal requirements and respond to lawful requests by public authorities.</li>
                        </ul>


                        <br />
                        <p><strong>4. How We Share Your Information</strong></p>
                        <p>We may share your information with third parties under the following circumstances:</p>

                        <ul>
                            <p><strong>a. With Your Consent:</strong></p>
                            <li>We may share your information with third parties when you have provided your explicit consent.</li>
                        </ul>

                        <ul>
                            <p><strong>b. Service Providers:</strong></p>
                            <li>We engage third-party service providers to perform various services on our behalf, such as payment processing, data analysis, and customer support.</li>
                        </ul>

                        <ul>
                            <p><strong>c. Legal Requirements:</strong></p>
                            <li>We may disclose your information to comply with legal obligations, respond to legal processes, or protect the rights, property, and safety of our users and others.</li>
                        </ul>

                        <br />
                        <p><strong>5. Data Security</strong></p>
                        <p>We implement a variety of security measures to ensure the protection of your personal information. These include encryption, access controls, and regular security assessments. However, no security system is impenetrable, and we cannot guarantee the absolute security of your data.</p>

                        <br />
                        <p><strong>6. Data Retention</strong></p>
                        <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce our agreements.</p>


                        <br />
                        <p><strong>7. Your Rights and Choices</strong></p>
                        <p>You have the following rights regarding your personal information:</p>

                        <ul>
                            <p><strong>a. Access and Update:</strong></p>
                            <li>You can access and update your personal information through your account settings.</li>
                        </ul>

                        <ul>
                            <p><strong>b. Delete:</strong></p>
                            <li>You can request the deletion of your personal information, subject to certain legal restrictions.</li>
                        </ul>

                        <ul>
                            <p><strong>c. Opt-Out:</strong></p>
                            <li>You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications.</li>
                        </ul>



                        <br />
                        <p><strong>8. Children's Privacy</strong></p>
                        <p>Our services are intended for use by schools and educational institutions. We do not knowingly collect personal information from children under the age of 13 without parental consent. If we become aware that we have collected personal information from a child under 13 without parental consent, we will take steps to delete that information.</p>


                        <br />
                        <p><strong>9. Changes to This Privacy Policy</strong></p>
                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website and mobile applications. Your continued use of our services after such changes constitutes your acceptance of the updated Privacy Policy.</p>

                        <br />
                        <p><strong>10. Contact Us</strong></p>
                        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

                        <p>
                            Barekaab School Management ERP <br />
                            Address: KM5, Hodan Mogadishu, Banadir Somalia
                            <br />
                            <a href="maito:info@barekaab.com">Info@barekaab.com</a>
                            <br />
                            <a href="tel:+252610505051">+252610505051</a>
                        </p>

                        <p>Thank you for trusting Barekaab with your school management needs. We are committed to protecting your privacy and ensuring a secure and efficient experience for all users.
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Privacy;