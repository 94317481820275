import React from 'react';
import Hero from '../../Components/Home/Hero';
import Marquee from '../../Components/Home/Marquee';
import Features from '../../Components/Home/Features';
import OurProduct from '../../Components/Home/OurProduct';
import AboutUs from '../../Components/Home/AboutUs';
import Testimonials from '../../Components/Home/Testimonials';
import FAQ from '../../Components/Home/FAQ';
import OurClient from '../../Components/Home/OurClient';
import WhyUs from '../../Components/Home/WhyUs';
import ProductBenefit from '../../Components/Home/ProductBenefit';

const HomePage = () => {
  return (
    <>
    <section className="page-wrapper">
      <Hero />
      <Marquee />
      <Features />
      <OurProduct />
      <ProductBenefit />
      <AboutUs />
      <WhyUs />
      <OurClient />
      <FAQ />
      <Testimonials />
    </section>
    </>
  )
}

export default HomePage;