import React from 'react'

const Terms = () => {
    return (
        <>
            <div className="container">
                <div className="privacy-card">
                    <div className="privacy-title">
                        <span>Terms & Conditions</span>
                    </div>
                    <hr />
                    <div className="privacy-content">
                        <h5>Terms & Conditions for Barekaab School Management ERP</h5>
                        <h6>Updated On: 13/06/2024</h6>

                        <p>Welcome to Barekaab School Management ERP. By accessing or using our web and mobile applications, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services.</p>

                        <br />
                        <p><strong>1. Acceptance of Terms</strong></p>
                        <p>By using Barekaab School Management ERP, you agree to these Terms & Conditions and our Privacy Policy. If you do not agree to these terms, you must not use our services.</p>

                        <br />
                        <p><strong>2. Services Provided</strong></p>
                        <p>Barekaab offers a comprehensive school management system that includes functionalities for administrators, teachers, students, parents, and support staff. Our services aim to facilitate efficient management and communication within educational institutions.</p>

                        <br />
                        <p><strong>3. User Accounts</strong></p>

                        <ul>
                            <p><strong>a. Account Creation:</strong></p>
                            <li>To use our services, you must create an account and provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials.</li>
                        </ul>

                        <ul>
                            <p><strong>b. Account Usage:</strong></p>
                            <li>You agree to use your account only for lawful purposes and in accordance with these terms. Unauthorized use of your account may result in termination of your access to our services.</li>
                        </ul>

                        <br />
                        <p><strong>4. User Responsibilities</strong></p>

                        <ul>
                            <p><strong>a. Compliance:</strong></p>
                            <li>Users must comply with all applicable laws and regulations while using Barekaab School Management ERP.</li>
                        </ul>

                        <ul>
                            <p><strong>b. Prohibited Activities:</strong></p>
                            <li>Users must not engage in any activity that could harm our services or other users. This includes, but is not limited to, hacking, spreading malware, or conducting fraudulent activities.</li>
                        </ul>

                        <br />
                        <p><strong>5. Intellectual Property</strong></p>
                        <ul>
                            <p><strong>a. Ownership</strong></p>
                            <li>Barekaab owns all intellectual property rights related to the software, including trademarks, logos, and content.</li>
                        </ul>

                        <ul>
                            <p><strong>a. Limited License:</strong></p>
                            <li>Users are granted a limited, non-exclusive, non-transferable license to use Barekaab School Management ERP in accordance with these terms.</li>
                        </ul>

                        <br />
                        <p><strong>6. Data Privacy</strong></p>
                        <p>Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our services, you consent to the data practices described in our Privacy Policy.</p>


                        <br />
                        <p><strong>7. Fees and Payments</strong></p>

                        <ul>
                            <p><strong>a. Subscription Fees:</strong></p>
                            <li>Some features of Barekaab School Management ERP may require a subscription fee. The fee structure will be communicated to you during the subscription process.</li>
                        </ul>

                        <ul>
                            <p><strong>b. Payment Terms:</strong></p>
                            <li>All payments must be made in accordance with the payment terms specified. Failure to make timely payments may result in suspension or termination of your access to our services.</li>
                        </ul>

                        <br />
                        <p><strong>8. Termination</strong></p>
                        <ul>
                            <p><strong>a. By User::</strong></p>
                            <li>You may terminate your account at any time by contacting us.</li>
                        </ul>
                        <ul>
                            <p><strong>b. By Barekaab:</strong></p>
                            <li>We reserve the right to terminate or suspend your account for violations of these terms or any other conduct that we deem harmful to our services or other users.</li>
                        </ul>


                        <br />
                        <p><strong>9. Limitation of Liability</strong></p>
                        <p>Barekaab is not liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of our services. Our total liability to you for any claim arising out of or relating to these terms or our services is limited to the amount you paid us, if any, for access to and use of Barekaab School Management ERP.</p>

                        <br />
                        <p><strong>10. Disclaimers</strong></p>
                        <p>Barekaab provides its services on an "as is" and "as available" basis. We do not warrant that the services will be uninterrupted, error-free, or free of harmful components.</p>

                        <br />
                        <p><strong>11. Governing Law</strong></p>
                        <p>These terms are governed by and construed in accordance with the laws of Somalia. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts of Somalia.</p>

                        <br />
                        <p><strong>12. Changes to Terms</strong></p>
                        <p>We may update these Terms & Conditions from time to time. We will notify you of any material changes by posting the updated terms on our website and mobile applications. Your continued use of our services after such changes constitutes your acceptance of the updated terms.</p>

                        <br />
                        <p><strong>13. Contact Us</strong></p>
                        <p>If you have any questions or concerns about these Terms & Conditions, please contact us at:</p>

                        <p>
                            Barekaab School Management ERP <br />
                            Address: KM5, Hodan Mogadishu, Banadir Somalia
                            <br />
                            <a href="maito:info@barekaab.com">Info@barekaab.com</a>
                            <br />
                            <a href="tel:+252610505051">+252610505051</a>
                        </p>

                        <p>By using Barekaab School Management ERP, you acknowledge that you have read, understood, and agree to be bound by these Terms & Conditions. Thank you for choosing Barekaab for your school management needs.
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Terms;