import React from "react";
import advance from "../../Assets/hero/business-bg.webp";
import { useNavigate } from "react-router-dom";

const AdvanceBusiness = () => {
  const navigate = useNavigate();
  const goContactUs = () => {
    navigate("/contact");
  };
  return (
    <>
      <section className="advance-business">
        <div className="container">
          <div className="advance-card-section">
            <div className="advance-card">
              <div className="advance-card-content">
                <h3>
                  <span style={{ color: "#136ad5" }}>Advance</span> Your <br />
                  Institute With Us
                </h3>
                <button onClick={goContactUs}>Rquest Call</button>
              </div>
              <div className="advance-card-img">
                <img src={advance} alt="advance" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdvanceBusiness;
