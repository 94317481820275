import React, { useState } from "react";
import footer from "../../Assets/logo/logo.webp";
import fb from "../../Assets/icon/facebook.png";
import li from "../../Assets/icon/linkedIn.png";
import x from "../../Assets/icon/twitter-x.png";
import In from "../../Assets/icon/instagram.png";
import Select from "react-select";
import { Link } from "react-router-dom";

const Footer = () => {
  const languageOptions = [
    { value: "en", label: "English" },
    { value: "hn", label: "Hindi" },
    { value: "fr", label: "French" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);
  // const [translatedText, setTranslatedText] = useState(null);

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    // translateText("Your text to translate", selectedOption.value);
  };

  // const translateText = async (text, targetLanguage) => {
  //   const API_KEY = "GOOGLE_TRANSLATE_API_KEY";
  //   const response = await fetch(
  //     `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}&q=${text}&target=${targetLanguage}`
  //   );
  //   const data = await response.json();
  //   setTranslatedText(data.data.translations[0].translatedText);
  // };

  return (
    <section className="footer-section" style={{ position: "relative" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="footer-column">
              <div className="footer-logo">
                <img src={footer} alt="footer-logo" />
                <p>
                  {/* {translatedText
                    ? translatedText
                    : "Welcome to Barekaab School ERP!"} */}
                  Welcome to Barekaab School ERP!
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="footer-column">
              <div className="row">
                <div className="col-lg-4">
                  <h3>SOFTWARE</h3>
                  <ul>
                    {/* <li><Link to="/">{translatedText ? translatedText : "Web Applications"}</Link></li>
                    <li><Link to="/">{translatedText ? translatedText : "Mobile Applications"}</Link></li>
                    <li><Link to="/">{translatedText ? translatedText : "Integration"}</Link></li> */}

                    <li>
                      <Link to="/software"> Web Applications</Link>
                    </li>
                    <li>
                      <Link to="/software">Mobile Applications</Link>
                    </li>
                    <li>
                      <Link to="/">Integration</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <h3>SOLUTIONS</h3>
                  <ul>
                    {/* <li><Link to="/">{translatedText ? translatedText : "For Schools"}</Link></li>
                    <li><Link to="/">{translatedText ? translatedText : "For Colleges"}</Link></li>
                    <li><Link to="/">{translatedText ? translatedText : "Modules"}</Link></li>
                    <li>
                      <Link to="/">{translatedText ? translatedText : "Portfolio Websites"}</Link>
                    </li> */}

                    <li>
                      <Link to="/solution">For Schools</Link>
                    </li>
                    <li>
                      <Link to="/solution">For Colleges</Link>
                    </li>
                    <li>
                      <Link to="/solution">Modules</Link>
                    </li>
                    <li>
                      <Link to="/solution">Portfolio Websites</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <h3>PRICING</h3>
                  <ul>
                    {/* <li><Link to="/">{translatedText ? translatedText : "Support"}</Link></li>
                    <li><Link to="/">{translatedText ? translatedText : "Resources"}</Link></li>
                    <li><Link to="/">{translatedText ? translatedText : "Policies"}</Link></li> */}

                    <li>
                      <Link to="/contact">Support</Link>
                    </li>
                    <li>
                      <Link to="/solution">Resources</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Policies</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-column">
              <div className="social-icons">
                <div className="fb-icons">
                  <img src={fb} alt="fb" />
                </div>
                <div className="li-icons">
                  <img src={li} alt="" />
                </div>
                <div className="x-icons">
                  <img src={x} alt="" />
                </div>
                <div className="in-icons">
                  <img src={In} alt="" />
                </div>
              </div>
              <div className="input-language">
                <Select
                  options={languageOptions}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <hr style={{ color: "#fff" }} />
        <div className="row">
          <div className="col-lg-6">
            <div className="copyright-content">
              <p>
                {/* {translatedText
                  ? translatedText
                  : "Copyright, Barekaab 2024. All Rights Reserved."} */}
                Copyright, Barekaab 2024. All Rights Reserved.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="copyright-content text-end">
              <p>
                {/* {translatedText ? translatedText : "Terms & Conditions"} */}
                Powered by{" "}
                <b>
                  <a
                    href="https://levontechno.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    Levon Techno
                  </a>
                  .
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="whatsapp-button">
        <a href="https://wa.me/919108777077" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-whatsapp"></i>
        </a>
      </div>
    </section>
  );
};

export default Footer;
