import React from 'react';
import PricingCard from '../../Components/Pricing/PricingCard';
import PricingTable from '../../Components/Pricing/PricingTable';

const Pricing = () => {
  return (
    <>
    <section className="page-wrapper">
        <PricingCard />
        <PricingTable />
    </section>
    </>
  )
}

export default Pricing;