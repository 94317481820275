import React from "react";
import team from "../../Assets/hero/team.webp";

const OurTeam = () => {
  return (
    <>
      <section className="our-team-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="our-team-content">
                <h3>
                  Our Remarkable Team at{" "}
                  <span style={{ color: "#136ad5" }}>Barekaab</span>
                </h3>
                <p>
                  Barekaab's ERP system revolutionizes school management with
                  dedicated apps, streamlining processes and enhancing
                  communication for all stakeholders, ushering in an efficient
                  future for education.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
                <div className="our-team-img">
                    <img src={team} alt="team" />
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurTeam;
