import React from "react";
import client1 from "../../Assets/partners/client-logo-1.webp";
import client2 from "../../Assets/partners/client-logo-2.webp";
import client3 from "../../Assets/partners/client-logo-3.webp";
import client4 from "../../Assets/partners/client-logo-4.webp";
import client5 from "../../Assets/partners/client-logo-5.webp";
import client6 from "../../Assets/partners/client-logo-6.webp";

const Partners = () => {
  return (
    <>
      <section className="about-partners-section">
        <div className="partners-marquee">
          <div className="partners-logo">
            <img src={client1} alt="client1" />
            <img src={client2} alt="client2" />
            <img src={client3} alt="client3" />
            <img src={client4} alt="client4" />
            <img src={client5} alt="client5" />
            <img src={client6} alt="client6" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Partners;
